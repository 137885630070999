import { useEffect, useRef, useState } from 'react';
import { Box, Stack, Button } from '@chakra-ui/react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

function removeAlpha(hexColor) {
    if (hexColor.length === 9) {
        return hexColor.substring(0, 7);
    }
    return hexColor;
}

const ColorImagePicker = ({ onChange, value = null }) => {
    const [colorImagePairs, setColorImagePairs] = useState([]);

    useEffect(() => {
        if (!colorImagePairs?.length) {
            const withIds = (value || []).map(item => ({ ...item, id: uuidv4() })); // Add unique id to each pair
            setColorImagePairs(withIds);
        }
    }, [value]);

    useEffect(() => {
        if (onChange)
            onChange(colorImagePairs)
    }, [colorImagePairs, onChange]);

    const handleColorChange = (index, newColor) => {
        const newPairs = [...colorImagePairs];
        newPairs[index].color = newColor;
        setColorImagePairs(newPairs);
    }

    const handleImageChange = async (index, newFile) => {
        const formData = new FormData();
        formData.append('file', newFile);

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/api/utils/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        const newPairs = [...colorImagePairs];
        newPairs[index].file = newFile;
        newPairs[index].url = res.data.fileUrl;

        const urls = newPairs.map(pair => pair.url);

        const resColor = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/api/utils/get_dominant_colors`, { urls });
        
        resColor.data.dominant_colors.forEach((color, i) => {
            newPairs[i].color = color;
        });
        console.log({newPairs});
        setColorImagePairs(newPairs);
    }

    const handleDelete = (index) => {
        const newPairs = [...colorImagePairs];
        newPairs.splice(index, 1);
        setColorImagePairs(newPairs);
    }

    const addNewPair = () => {
        const newPair = { color: '#000000', file: null, url: null, id: uuidv4() }; // Added id here
        setColorImagePairs([...colorImagePairs, newPair]);
    };

    return (
        <div className="input-div pass">
            <div className="div urls">
                <Box>
                    {colorImagePairs?.map((pair, index) => (
                        <Stack key={pair.id} direction='row'> {/* Use id here */}
                            <div className="image-color-container">
                                <input
                                    type='file'
                                    accept='image/*'
                                    onChange={(e) => {
                                        handleImageChange(index, e.target.files[0]);
                                    }}
                                />
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <input type="color" value={removeAlpha(pair.color)} onChange={(e) => handleColorChange(index, e.target.value)} />
                                    <div style={{ marginLeft: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        {pair.url &&
                                            <img
                                                src={pair.url}
                                                style={{ width: 70, height: 70 }}
                                                alt=''
                                            />
                                        }
                                        <Button onClick={() => handleDelete(index)} size="sm" colorScheme="red">Delete</Button>
                                    </div>
                                </div>
                            </div>
                        </Stack>
                    ))}
                    <Button onClick={addNewPair} colorScheme="blue">Ajouter une image et couleur</Button>
                </Box>
            </div>
        </div>
    );
}

export default ColorImagePicker;
