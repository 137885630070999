import React, { useEffect, useState } from 'react';
import { DeleteProduct, listProducts, CreateProduct } from '../../actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
import HashLoader from "react-spinners/HashLoader";
import { PRODUCT_CREATE_RESET } from '../../constants/productConstants';
import './products.css';
import { Button, Table, Thead, Tbody, Tr, Th, Td, Stack, Box, Input } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai';
import { CgAdd } from 'react-icons/cg';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const Products = ({ history, match }) => {
    const dispatch = useDispatch();
    const productList = useSelector(state => state.productList);
    const { loading, error, products: allProducts } = productList;
    const productDelete = useSelector(state => state.productDelete);
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = productDelete;
    const productCreate = useSelector(state => state.productCreate);
    const { loading: loadingCreate, error: errorCreate, success: successCreate, product: createdproduct } = productCreate;
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(5);
    const [sortType, setSortType] = useState("asc");
    const [columnToSort, setColumnToSort] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (searchTerm) {
            setProducts(
                allProducts
                    .filter(product =>
                        product.name.toLowerCase().includes(searchTerm.toLowerCase())
                    )
            );
        } else {
            setProducts(allProducts);
        }
    }, [searchTerm, allProducts]);

    const sortItems = (a, b) => {
        if (a[columnToSort] < b[columnToSort]) {
            return sortType === "asc" ? -1 : 1;
        }
        if (a[columnToSort] > b[columnToSort]) {
            return sortType === "asc" ? 1 : -1;
        }
        return 0;
    };

    const sortedProducts = [...products].sort(sortItems);

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleSort = (columnName) => {
        setSortType(sortType === "asc" ? "desc" : "asc");
        setColumnToSort(columnName);
    }

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(products.length / productsPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        dispatch({ type: PRODUCT_CREATE_RESET });
        if (!userInfo.isAdmin) {
            navigate('/login');
        }
        if (successCreate) {
            navigate(`/admin/product/${createdproduct._id}/edit`);
        } else {
            dispatch(listProducts());
        }
    }, [dispatch, history, userInfo, successDelete, successCreate, createdproduct]);

    const deletehandler = (id) => {
        if (window.confirm('Are You Sure?')) {
            dispatch(DeleteProduct(id));
        }
    }

    const createproducthandler = () => {
        dispatch(CreateProduct());
    }

    return (
        <div className='Users'>
            <Helmet>
                <title>Produits</title>
            </Helmet>
            <h1 className='titlepanel'> Produits :</h1>
            {loading || loadingDelete || loadingCreate ? (
                <div className='loading'>
                    <HashLoader color={"#1e1e2c"} loading={loading || loadingDelete || loadingCreate} size={40} />
                </div>
            ) : error || errorDelete || errorCreate ? (
                <h1>{error || errorDelete || errorCreate}</h1>
            ) : (
                <>
                    <Button leftIcon={<CgAdd size='20' />} className='ADDBUTTON' colorScheme='blue' onClick={createproducthandler}>Ajouter</Button>
                    <Input placeholder="Recherche..." onChange={event => { setSearchTerm(event.target.value) }} />
                    <Box overflowX='auto'>
                        <Table className='productusers' variant="striped">
                            <Thead>
                                <Tr>
                                    <Th w='10%' onClick={() => handleSort('id')}>ID</Th>
                                    <Th w='20%' onClick={() => handleSort('name')}>Nom</Th>
                                    <Th w='20%' onClick={() => handleSort('price')}>Prix</Th>
                                    <Th w='20%' onClick={() => handleSort('category')}>Catégorie(s)</Th>
                                    <Th w='10%'></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {currentProducts.map(product => (
                                    <Tr key={product._id}>
                                        <Td>{product._id}</Td>
                                        <Td>{product.name}</Td>
                                        <Td>{product.price}</Td>
                                        <Td>{product.category}</Td>
                                        <Td className='editdeleteicons'>
                                            <Link to={`/admin/product/${product._id}/edit`} >
                                                <AiOutlineEdit size='20' color='green' className='editicon' />
                                            </Link>
                                            <AiFillDelete size='20' color='red' className='deleteicon' onClick={() => deletehandler(product._id)} />
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Stack spacing={2} mt={4} direction="row" justify="center" align="center">
                        {pageNumbers.map(number => (
                            <Button onClick={() => paginate(number)} key={number} variant="outline">
                                {number}
                            </Button>
                        ))}
                    </Stack>
                </>
            )}
        </div>
    );
}

export default Products;
