 const config = {
    aboutTitle: 'À propos',
    segunAdebayoAltText: 'Segun Adebayo',
    welcomeText: 'Bienvenue dans notre animalerie! Nous sommes fiers de vous proposer une large gamme de produits pour animaux de compagnie à des prix abordables. Chez nous, vous pouvez trouver tout ce dont vous avez besoin pour prendre soin de votre animal, que ce soit de la nourriture, des jouets ou des accessoires. Nous sommes convaincus que chaque animal mérite les meilleurs soins possibles, c\'est pourquoi nous nous engageons à offrir des produits de qualité et à sensibiliser nos clients à l\'importance des soins pour animaux. De plus, nous livrons directement chez vous pour votre commodité. Venez découvrir notre sélection de produits pour animaux de compagnie et offrez à votre ami à fourrure le meilleur de ce dont il a besoin.',
 
    emptyCardTitle: 'Votre panier est vide',
    emptyCardText: 'Aller magasiner',
    filterDefault: 'Default',
    filterRating: 'Rating',
    filterDate: 'Date',
    filterLowToHigh: 'Low to high price',
    filterHighToLow: 'High to low price',
   };
  export default config;