import React, { useEffect, useState } from 'react'
import { listOrders } from '../../actions/orderActions'
import { useDispatch, useSelector } from 'react-redux'
import HashLoader from "react-spinners/HashLoader"
import { Helmet } from 'react-helmet'
import {
    Button, Input, Table,  Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Stack,
    Box,
} from "@chakra-ui/react"
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'

const Orders = ({history}) => {
    const dispatch = useDispatch()
    const orderList = useSelector(state => state.orderList)
    const { loading, error, orders: allOrders } = orderList
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(1)
    const [ordersPerPage] = useState(5)
    const [sortType, setSortType] = useState("asc")
    const [columnToSort, setColumnToSort] = useState("")
    const [searchTerm, setSearchTerm] = useState("")
    const [orders, setOrders] = useState([])


    
    useEffect(() => {
        if (searchTerm) {
            setOrders(
                allOrders
                    .filter(order =>
                        order.user.name.toLowerCase().includes(searchTerm.toLowerCase())
                    )
            )
        } else {
            setOrders(allOrders || [])
        }
    }, [searchTerm, allOrders])

    const sortItems = (a, b) => {
        if (a[columnToSort] < b[columnToSort]) {
            return sortType === "asc" ? -1 : 1
        }
        if (a[columnToSort] > b[columnToSort]) {
            return sortType === "asc" ? 1 : -1
        }
        return 0
    }
    const sortedOrders = [...(orders || [])].sort(sortItems)

    const indexOfLastOrder = currentPage * ordersPerPage
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage
    const currentOrders = sortedOrders.slice(indexOfFirstOrder, indexOfLastOrder)

    const paginate = pageNumber => setCurrentPage(pageNumber)

    const handleSort = (columnName) => {
        setSortType(sortType === "asc" ? "desc" : "asc")
        setColumnToSort(columnName)
    }

    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(orders.length / ordersPerPage); i++) {
        pageNumbers.push(i)
    }

    useEffect(() => {
        if (!userInfo.isAdmin) {
            navigate('/login')
        } else {
            dispatch(listOrders())
        }
    }, [dispatch, navigate, userInfo])

    return (
        <div className='Users'>
            <Helmet>
                <title>Orders</title>
            </Helmet>
            <h1 className='titlepanel'> Orders :</h1>
            {loading ? (
                <div className='loading'>
                    <HashLoader color={"#1e1e2c"} loading={loading} size={40} />
                </div>
            ) : error ? (
                <h1>{error}</h1>
            ) : (
                <>
                    <Input placeholder="Recherche..." onChange={event => { setSearchTerm(event.target.value) }} />
                    <Box overflowX='auto'>
                        <Table className='tableusers' variant="striped">
                            <Thead>
                                <Tr>
                                    <Th w='10%' onClick={() => handleSort('_id')}>ID</Th>
                                    <Th w='20%' onClick={() => handleSort('user')}>User</Th>
                                    <Th w='20%' onClick={() => handleSort('createdAt')}>Date</Th>
                                    <Th w='10%' onClick={() => handleSort('totalPrice')}>TOTAL</Th>
                                    <Th w='10%' onClick={() => handleSort('isPaid')}>PAID</Th>
                                    <Th w='10%' onClick={() => handleSort('isDelivered')}>Deliverd</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {currentOrders.map(order => (
                                    <Tr key={order._id}>
                                        <Td>{order._id}</Td>
                                        <Td>{order.user && order.user.name}</Td>
                                        <Td>{order.createdAt.substring(0,10)}</Td>
                                        <Td>{order.totalPrice}</Td>
                                        <Td>{order.isPaid ? <div className='paid'>{order.paidAt.substring(0,10)}</div> : <div className='notpaid'>NO</div>}</Td>
                                        <Td>{order.isDelivered ? <div className='paid'>{order.deliveredAt.substring(0,10)}</div> : <div className='notpaid'>NO</div>}</Td>
                                        <Td>
                                            <Stack>
                                                <Link to={`/order/${order._id}`}>
                                                    <Button leftIcon={<AiOutlineEdit size='16' />} colorScheme='blue' size="xs">Details</Button>
                                                </Link>
                                            </Stack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Stack spacing={2} mt={4} direction="row" justify="center" align="center">
                        {pageNumbers.map(number => (
                            <Button onClick={() => paginate(number)} key={number} variant="outline">
                                {number}
                            </Button>
                        ))}
                    </Stack>
                </>
            )}
        </div>
    )
}

export default Orders
