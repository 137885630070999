import React, { useEffect } from 'react'
import ProductsC from '../components/ProductsC';
import  "./Shop.css";
import { Helmet } from 'react-helmet';

const Shop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);})
    return (
        <div className = 'shoppage'>
            <Helmet>
                <title>Boutique</title>
            </Helmet>
            <ProductsC />
        </div>
    )
}

export default Shop
