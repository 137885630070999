
import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Image } from "@chakra-ui/react";
import HashLoader from "react-spinners/HashLoader";
import ReactPlayer from "react-player";
import config from "../../config";
import "./aboutcss.css";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const About = () => {
  const Line = useRef(null);
  const text = useRef(null);
  const size = useWindowSize();
  const [videoReady, setVideoReady] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      Line.current?.classList.add("lineon");
      text.current?.classList.add("titleon");
    }, 5);
    return () => {};
  }, []);

  return (
    <>
      <Helmet>
        <title>{config.aboutTitle}</title>
      </Helmet>
      <div className="Content2">
        <div className="imagecontainer">
          <div className="Imageabt">
            <Image
              className="mImage"
              boxSize="400px"
              objectFit="cover"
              src={require("./../../assets/97d0c71534e0e910dc511513ee782e5f.gif")}
              alt={config.segunAdebayoAltText}
            />
          </div>
        </div>
        <div className="text">
          <p style={{ width: "87%" }}>
            {config.welcomeText}
          </p>
        </div>
      </div>
    </>
  );
};

export default About;

