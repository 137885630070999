import React, { useState, useEffect } from "react";
import Slider from "../components/Slider";
import Cardscg from "../components/Cardscg";
import CgDiv from "../components/CgDiv";
import ProductsC from "../components/ProductsC";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import ImageResponsive from "react-image-responsive";
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL  //"https://api.animalShop.tn";
const Home = () => {
  /*const [promoIframeSrc , setPromoIframeSrc] = useState("")
    useEffect(()=>{
       if ( ! promoIframeSrc?.length)
       {
        promoIframeSrc = await axios.get("/bannerUrl")
       }
    } ,[]);*/
  const [videoReady, setVideoReady] = React.useState(false);
  const size = useWindowSize();
  return (
    <>
      <Helmet>
        <title>Animalerie</title>
      </Helmet>
      <div>
        <Slider />
        <div className="Content2">
          <div className="text">
            <h1>Animalerie</h1>
            <div style={{ marginTop: 5, marginBottom: 20 }}>
              <lu>
                <li>Votre Première Animalerie à Gabès
Nourriture et accessoires pour animaux de compagnie </li>
              </lu>
            </div>
          </div>
          <div className="video-container">
            <center style={{ maxWidth: size.width, overflow: "hidden" }}>
              {
                <div style={{ opacity: videoReady ? 1 : 1 }}>
                  <img
                    src={"https://i.ibb.co/CVmxc5G/sabri-tuzcu-wun-VFNvqhf-E-unsplash-m-ULwj-Jpe.webp"}
                    light={true}
                    style={{
                      width: Math.min(size.width, 500) - 10
                    }}
                  />
                </div>
              }
            </center>
          </div>
        </div>

        <div className="cards">
          <Cardscg title="Chiens" navigateTo='Chien'/>
          <Cardscg title="Chats" navigateTo='Chat' />
          <Cardscg title="Autre" navigateTo='Autre' />
        </div>
        <CgDiv />
        <ProductsC />
      </div>
    </>
  );
};

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
export default Home;
