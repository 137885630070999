import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listProductDetails, UpdateProduct } from '../../actions/productActions'
import HashLoader from "react-spinners/HashLoader";
import { Input, InputGroup, } from '@chakra-ui/input'
import { Helmet } from 'react-helmet';

import { Box, Checkbox, color, Stack, Textarea, VStack } from '@chakra-ui/react'
import { PRODUCT_UPDATE_RESET } from '../../constants/productConstants';

import { SketchPicker } from 'react-color';
import { useParams } from 'react-router-dom'

import './Editproduct.css'
import ColorImagePicker from './ColorPicker';
const Editproduct = ({ history }) => {

    const { id: productId } = useParams();
    const [name, setName] = useState('')
    const [description, setdescription] = useState('')
    const [price, setprice] = useState(0)
    const [countInStock, setcountInStock] = useState(0)
    const [colorImagePairs, setColorImagePairs] = useState(null)

    const [sizes, setSizes] = useState([])
    const [category, setcategory] = useState([])
    const [categoryMap, setCategoryMap] = useState({
        'Chien': false,
        'Chat': false,
        'Croquettes': false,
        'Humides': false,
        'Compléments alimentaires': false,
        'Toilettage et hygiène': false,
        'Accessoires': false,
    })

    const [active, setActive] = useState(false)

    const [message, setMessage] = useState("")
    const [prices, setPrices] = useState([])
    const [promotion, setInPromotion] = useState(false)
  
    const [promotionPercent, setPromotionPercent] = useState(0)


    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails)

    const { loading, error, product } = productDetails

    const productUpdate = useSelector(state => state.productUpdate)

    const { loading: lodingUpdate, error: errorUpdate, success: successUpdate } = productUpdate


    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: PRODUCT_UPDATE_RESET })
            window.location.reload()
        }
        else {
            if (!product?.name || product?._id !== productId) {
                dispatch(listProductDetails(productId))
            } else {
                if ( name?.length !== 0 ) {
                    return;
                }

                setName(product.name)
                setprice(product.price)
                setdescription(product.description)
                setColorImagePairs((
                    product.images || []
                ).map(
                    (img, index) => ({
                        url: product.images[index],
                        color: product.imagesColors[index]
                    })
                ))
                setActive(product.active)

                if (
                    product.sizes[0] === "normal" && product.sizes[1]?.length || 0 + product.sizesPrices[1]?.length || 0 + product.sizes[2]?.length || 0 + product.sizesPrices[2]?.length || 0 + product.sizes[3]?.length || 0 + product.sizesPrices[3]?.length || 0 === 0
                ) {
                    setPrices([product.price])
                    setSizes(["normal"])
                } else {
                    setSizes(product.sizes)
                    setPrices(product.sizesPrices)
                }

                setSizes(product.sizes);
                setPrices(product.sizesPrices);
                setInPromotion(
                    (typeof product.promotion === "number" && product.promotion > 0) ||
                    (typeof product.promotion === "string" && parseInt(product.promotion) > 0)
                );
                setPromotionPercent(product.promotion);
                setcategory(product.category);
                setcountInStock(product.countInStock)
                setCategoryMap(
                    Object.fromEntries(
                        Object.entries(categoryMap).map(([key, value]) => {
                            return [key, product.category.includes(key)]
                        })
                    )
                )
            }
        }

        return () => {
        }
    }, [dispatch, productId, history, product, category, successUpdate, categoryMap])

    // set to true if at least one category is selected
    const catSelected = Object.values(categoryMap).some(e => e === true)

    const submitHandler = (e) => {
        if (!catSelected) {
            e.preventDefault();
            return;
        }

        e.preventDefault()
        let sizesCopy = [...sizes] 
        // alert sizes copy json.stringify
        let sizesPricesCopy = prices.filter((e, i) => sizesCopy[i] != null)
        sizesCopy = sizesCopy.filter((e, i) => e != null)
        if ((new Set(sizesCopy)).size !== sizesCopy.length) {
            if (!window.confirm("Size duplication found , you still want to update product ?")) {
                return;
            }
        }
        sizesPricesCopy = sizesPricesCopy.map((e, i) => e === null || e === ""  ||  sizes === null || sizes === "" ? null : e)
        sizesCopy = sizesCopy.map((e, i) => e === null || e === "" || sizesPricesCopy[i] === null || sizesPricesCopy[i] === "" ? null : e)
        // remove nulls
        sizesCopy = sizesCopy.filter((e, i) => e != null)
        sizesPricesCopy = sizesPricesCopy.filter((e, i) => e != null)

        if (sizesPricesCopy.filter((i) => i === null || i === "").length) {
            alert("You should set the price for every size")
            return;
        }
        let colors = colorImagePairs.map(e => e.color);

        if ((new Set(colors)).size !== colors.length) {
            alert("Color duplication found , every color should be unique with it's image");
            return;
        }

        let images = colorImagePairs.map(e => e.url);

        if ((new Set(images)).size !== images.length) {
            alert("Images duplication found , every color should be unique with it's image");
            return;
        }
        const updatedProduct = {
            _id: productId,
            name,
            price,
            Images: images,
            category : Object.entries(categoryMap).filter(([key, value]) => value).map(([key, value]) => key),
            sizes: sizesCopy,
            sizesPrices: sizesPricesCopy,
            countInStock,
            description,
            imagesColors: colors,
            active,
            promotion: promotion ? promotionPercent : 0
        }
        
        console.log({ updatedProduct });
        dispatch(UpdateProduct(updatedProduct))
    }

    const checkboxhandlercg = (D) => {
        let index = category.indexOf(D)
        if (index > -1) {
            category.splice(index, 1)
        } else {
            category.push(D)
        }
    }

    const defaultPriceLinkedToSizesPrices = () => {
  /*       return false;
        return (
            size1 === "normal" && size2?.length || 0 + price2?.length || 0 + size3?.length || 0 + price3?.length || 0 + size4?.length || 0 + price4?.length || 0 === 0
        ) */
    }
    return (
        <div className='Edituser'>
            <Helmet>
                <title>Modifier le produit</title>
            </Helmet>
            {error && <h4>{error}</h4>}
            {/* {successUpdate && <h4>Profile Updated</h4>} */}
            {loading || lodingUpdate ?
                <div className='loading'>
                    <HashLoader color={"#1e1e2c"} loading={lodingUpdate} size={40} />
                </div> : errorUpdate ? <h4>{errorUpdate}</h4> :
                    <div>
                        <h4 className='Edittitle'>Modifier le produit :</h4>
                        <div className='formedit'>
                            <form onSubmit={submitHandler}>
                                <div >
                                    <div className="input-div zz">
                                        Nom :
                                        <div className="div">
                                            <InputGroup>
                                                <Input type="text" value={name} placeholder="Entrez le nom" onChange={(e) => setName(e.target.value)} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="input-div one">
                                        Prix :
                                        <div className="div">
                                            <InputGroup>
                                                <Input type="text" value={price} placeholder="Entrez le prix" onChange={(e) => {
                                                    setprice(e.target.value)
                                                    if (defaultPriceLinkedToSizesPrices()) {
                                                        setPrices([e.target.value])
                                                    }
                                                }} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="input-div one">
                                        Quantités en stock :
                                        <div className="div">
                                            <InputGroup>
                                                <Input type="text" value={countInStock} placeholder="Entrez la quantités en stock" onChange={(e) => setcountInStock(e.target.value)} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    {/* product on promotion, we have  an input to enter the percentation */}
                                    <div className="input-div one">
                                        Promotion :
                                        <div className="div">
                                            <InputGroup>
                                                <Checkbox type="text" isChecked={promotion}  placeholder="Produit active"  onChange={(e) => setInPromotion(e.target.checked)} />
                                            </InputGroup>
                                            {/* if product is on promotion we have an input to enter the percentation */}
                                            {promotion && <div className="input-div one"
                                            key={'promotionPercent'}
                                            >  <div className="div">
                                                <InputGroup>
                                                    <Input type="text" value={promotionPercent} placeholder="Entrez la promotion" onChange={(e) => {
                                                            setPromotionPercent(e.target.value)
                                                    }} />
                                                </InputGroup>
                                            </div> 
                                            </div> 
                                            }
                                        </div>
                                    </div>
                                    <div className="input-div one">
                                        Description/Categorie
                                        <div className="div">
                                            <Stack direction='column' spacing={4}>
                                                <InputGroup>
                                                    <Textarea size='sm' value={description} placeholder="Entrez le prix" onChange={(e) => setdescription(e.target.value)} />
                                                </InputGroup>
                                                <Stack direction="column">
                                                    {
                                                        Object.entries(categoryMap).map(([key, value]) => {
                                                            return (
                                                                <Checkbox key={key} onChange={() => {
                                                                    let categoryMapCopy = { ...categoryMap }
                                                                    categoryMapCopy[key] = !categoryMapCopy[key]
                                                                    setCategoryMap(categoryMapCopy)
                                                                }} isChecked={categoryMap[key]}>{key}</Checkbox>
                                                            )
                                                        })
                                                    } 
                                                </Stack>
                                            </Stack>
                                        </div>
                                    </div>

                                    <div className="input-div one">
                                        Produit active :
                                        <div className="div">
                                            <InputGroup>
                                                <Checkbox type="text" isChecked={active}
                                                placeholder="Produit active" 
                                                onChange={(e) => {
                                                    setActive(e.target.checked)
                                                }
                                                }
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="input-div pass">
                                        <div className="div">
                                        </div>
                                    </div>

                                    <div className="input-div pass">
                                        Sizes:
                                        <div className="div">
                                            <Stack direction="row">
                                                {
                                                    // loop from 0 to 4
                                                    [...Array(4).keys()].map((i) => {
                                                        return (
                                                            <Box key={i}>
                                                                 <div className='pair-input' >
                                                                    <input  className="text-input-compact" type="text" value={sizes[i] || ""} placeholder={`taille ${i}`} onChange={(e) => {
                                                                        let sizesCopy = [...sizes]
                                                                        sizesCopy[i] = e.target.value
                                                                        setSizes(sizesCopy)
                                                                    }} />
                                                                    <input  className="text-input-compact" type="text" value={prices[i] || ""} placeholder={`prix ${i}`} onChange={(e) => {
                                                                        let pricesCopy = [...prices]
                                                                        pricesCopy[i] = e.target.value
                                                                        setPrices(pricesCopy)
                                                                    }} />
                                                                </div>
                                                            </Box>
                                                        )


                                                        
                                                    })
                                                }
                                            </Stack>
                                        </div>
                                    </div>
                                    <div className="input-div pass">
                                        Images de produits avec des couleurs:
                                        <div className="div urls">
                                            <ColorImagePicker
                                                value={colorImagePairs}
                                                onChange={setColorImagePairs}
                                            />
                                        </div>
                                    </div>
                                    {message && <h4 className='Message'>{message}</h4>}
                                    <input type="submit" className={"btna2 postionbtnupdate " + (!catSelected ? "update-product-disabled" : "")} value="Mettre à jour" />
                                </div>
                            </form>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Editproduct
