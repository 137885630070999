import {React , useState} from 'react'

 
const CgDiv = () => {
 

 
    return (
        <div>
           
        
        </div>
    )
}
 
export default CgDiv